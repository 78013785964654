import {Col, Row} from "react-bootstrap";
import GradeChart from './gradeChart'

const GradeCharts = (
  {
    className = "",
    printLoading = () => {
    },
    data = {},
  }) => {

  return <Row xs={1} xxl={2} className={className} style={{margin: "1rem 1rem 0rem", position: 'relative'}}>
    {printLoading()}
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={"Attention grades"}
        data={data.analytics.attention_grade_summary}
        score={data.analytics.attention_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={"Vigilance grades"}
        data={data.analytics.vigilance_grade_summary}
        score={data.analytics.vigilance_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={"Stress grades"}
        data={data.analytics.stress_grade_summary}
        score={data.analytics.stress_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={"Relaxation grades"}
        data={data.analytics.relaxation_grade_summary}
        score={data.analytics.relaxation_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 redesign-card"}>
      <GradeChart
        title={"Mental workload grades"}
        data={data.analytics.mental_workload_grade_summary}
        score={data.analytics.mental_workload_grade_summary?.score}
      />
    </Col>
    <Col xs={2} className={"mb-1 ml-5 redesign-card"}>
      <GradeChart
        title={"Motion sickness grades"}
        data={data.analytics.motion_sickness_grade_summary}
        score={data.analytics.motion_sickness_grade_summary?.score}
      />
    </Col>
  </Row>
}

export default GradeCharts;
