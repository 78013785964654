import * as am5 from '@amcharts/amcharts5'
import SemiPieChartAM5 from '../../charts/semiPieChartAM5'
import {BASELINE_COLOR, ORANGE_COLOR, POOR_COLOR, SUFFICIENT_COLOR} from './colors'

const GradeChart = (
  {
    title = "",
    data = [],
    score = 0,
  }) => {
  if (!data) {
    return null
  }

  score = parseFloat(score.toFixed(1))

  const chartData = [{
    "name": "Poor",
    "value": data.poor || 0,
  }, {
    "name": "Baseline",
    "value": data.baseline || 0,
  }, {
    "name": "Excellent",
    "value": data.sufficient || 0,
  }]

  return <SemiPieChartAM5
    title={title}
    categoryField={"name"}
    valueField={"value"}
    data={chartData}
    colors={[POOR_COLOR, BASELINE_COLOR, SUFFICIENT_COLOR]}
    score={score}
  />;
}

export default GradeChart;
