import {Row} from 'react-bootstrap'
import DivergentStackedChartAM5 from '../../charts/divergentStackedChartAM5'
import ClusteredBarChartAM5 from '../../charts/clusteredBarChartAM5'

const DurationChart = (
  {
    className = "",
    printLoading = () => {},
    data = [],
  }) => {
  if (!data || data.length === 0) {
    return null
  }

  const prepareData = []
  for (let i = 0; i < data.length; i++) {
    prepareData.push({
      name: data[i].name || data[i].title,
      duration: parseFloat(data[i].duration.toFixed(1)),
    })
  }

  prepareData.sort((a, b) => {
    return a.duration > b.duration ? 1 : -1
  });

  const chartData = []

  for (let i = 0; i < prepareData.length; i++) {
    chartData.push({
      name: prepareData[i].name,
      duration: prepareData[i].duration,
    })
  }

  return <Row xs={1} xxl={1} className={`${className} redesign-card mt-5`}
              style={{margin: "1rem 1rem 0rem", position: 'relative'}}>
    {printLoading()}
    <ClusteredBarChartAM5
      title="Duration (minutes)"
      valueField="duration"
      categoryField="name"
      data={chartData}
    />
  </Row>;
}

export default DurationChart;
